export const isValid = function() {
  let nameIsValid = nameIsPresent(this.button_set.name);
  let buttonsValid = buttonsAreValid(this.button_set.buttons);
  return nameIsValid && buttonsValid;
};

const nameIsPresent = name => {
  return name !== null;
};

const buttonsAreValid = buttons => {
  let allValid = true;
  for (let button of buttons) {
    button.label ? null : (allValid = false);
    buttonValidation(button) ? null : (allValid = false);
  }
  return allValid;
};

const buttonValidation = button => {
  switch (button.target_type) {
    case "url":
    case "embed":
    case "phone":
      return button.target !== null;
    case "email":
      return buttonEmailValid(button.target);
  }
};

const buttonEmailValid = mailToURL => {
  if (!mailToURL) return false;
  let parts = mailToURL.split(/[?|&]/);
  if (parts.length < 3) return false;

  let valid = true;
  ["mailto:", "subject=", "body="].forEach(el => {
    let string = parts.find(el => el.startsWith(el));
    if (string && string.replace(el, "") === "") valid = false;
  });
  return valid;
};
