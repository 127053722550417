<template>
  <nav class="ci-breadcrumbs" aria-label="breadcrumb">
    <ol class="ci-breadcrumbs__list">
      <li
        v-for="(breadcrumb, i) in breadcrumbs"
        :key="i"
        class="ci-breadcrumbs__list-item"
        :aria-current="last === i && 'page'"
      >
        <template v-if="last !== i || breadcrumb.link">
          <slot name="link" v-bind="{ breadcrumb }">
            <router-link :to="breadcrumb.link" class="ci-breadcrumbs__link">
              {{ breadcrumb.text }}
            </router-link>
          </slot>
        </template>
        <template v-else>
          <slot name="current" v-bind="{ breadcrumb }">
            <span class="ci-breadcrumbs__link ci-breadcrumbs__link--current">
              {{ breadcrumb.text }}
            </span>
          </slot>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "SfBreadcrumbs",

  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    last() {
      return this.breadcrumbs.length - 1;
    }
  }
};
</script>

<style lang="scss">
.ci-breadcrumbs {
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &__list-item {
    &:not(:last-child) {
      &::after {
        content: "/";
        color: $grey-6;
        padding: 0 0.5rem;
      }
    }
  }
  &__link {
    color: $grey-7;

    &:hover {
      color: $grey-8;
    }
    &--current {
      color: $grey-8;
      cursor: default;
    }
  }
}
</style>
