<template>
  <div>
    <RdxButton
      outline
      class="mr-2"
      data-cy="cancel"
      :disabled="pending"
      @click="cancel"
    >
      {{ $t("global.cancel") }}
    </RdxButton>
    <RdxButton
      filled
      :disabled="!isValid || pending"
      data-cy="save"
      @click="saveButtonSet"
    >
      {{ $t("global.save") }}
    </RdxButton>
  </div>
</template>

<script>
import { RdxButton } from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "NewButtonSetButtons",

  components: { RdxButton },
  props: {
    pending: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    saveButtonSet() {
      this.$emit("saveButtonSet");
    }
  }
};
</script>
