<template>
  <div class="ci-button-edit-form pb-2">
    <div class="ci-button-edit-form__from-group">
      <RdxInput
        :value="button.label"
        :placeholder="labelPlaceholder"
        :label="$t('button-sets.form.label-input-label')"
        @input="update('label', $event)"
      />
    </div>

    <div class="ci-button-edit-form__from-group">
      <label>
        {{ $t("button-sets.form.target-type-label") }}
      </label>
      <div class="ci-button-edit-form__radio-container">
        <RdxRadio
          v-for="link_type of link_types"
          :key="link_type.value"
          :model-value="button.target_type"
          :value="link_type.value"
          :label="$t(`button-sets.form.${link_type.value}-target-label`)"
          @input="update('target_type', $event)"
        />
      </div>
    </div>

    <div class="ci-button-edit-form__from-group">
      <div v-if="button.target_type === 'url'">
        <RdxInput
          :value="button.target"
          type="url"
          :label="$t(`button-sets.form.url-input-label`)"
          placeholder="https://"
          @input="update('target', $event)"
        />
      </div>
      <div v-if="button.target_type === 'embed'">
        <RdxTextarea
          :value="button.target"
          :label="$t(`button-sets.form.html-code-input-label`)"
          :autosize="false"
          rows="4"
          @input="update('target', $event)"
        />
      </div>
      <CiButtonSetEmailPartial
        v-if="button.target_type === 'email'"
        :value="button.target"
        @input="update('target', $event)"
      />
      <div v-if="button.target_type === 'phone'">
        <RdxInput
          :value="button.target"
          :label="$t(`button-sets.form.phone-input-label`)"
          type="number"
          @input="update('target', $event)"
        />
      </div>
    </div>

    <div
      v-if="button.target_type === 'url'"
      class="ci-button-edit-form__from-group"
    >
      <label>
        {{ $t("button-sets.form.target-action-label") }}
      </label>
      <div class="ci-button-edit-form__radio-container">
        <RdxRadio
          v-for="target of targets"
          :key="`input-${target.value}`"
          :model-value="button.target_action"
          :value="target.value"
          :label="$t(`button-sets.form.${target.value}-action-label`)"
          @input="update('target_action', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RdxInput,
  RdxTextarea,
  RdxRadio
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiButtonSetEmailPartial from "./CiButtonSetEmailPartial";

export default {
  name: "CiButtonSetEditForm",

  components: {
    RdxInput,
    RdxTextarea,
    RdxRadio,

    CiButtonSetEmailPartial
  },

  props: {
    value: {
      type: Object,
      default: null
    },
    labelPlaceholder: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      link_types: [
        { value: "url", label: "Custom URL" },
        { value: "embed", label: "Embed" },
        { value: "email", label: "Mail" },
        { value: "phone", label: "Phone" }
      ],

      targets: [
        { value: "new_tab", label: "In the blank Tab" },
        { value: "modal", label: "In the modal" },
        { value: "self", label: "In the same Tab" }
      ],

      button: {}
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        Object.entries(newValue).forEach(el => {
          this.$set(this.button, el[0], el[1]);
        });
      }
    },

    "button.target_type"(newValue, oldValue) {
      if (oldValue) this.update("target", null);
    }
  },

  methods: {
    update(key, value) {
      this.$emit("input", { ...this.button, [key]: value });
    }
  }
};
</script>

<style lang="scss">
.ci-button-edit-form {
  &__from-group {
    padding-top: 0px;
  }

  &__radio-container {
    .rdx-radio {
      display: inline-block;
      margin-right: 16px;
    }
  }

  input[type="number"] {
    appearance: textfield;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
