<template>
  <div>
    <RdxInput
      v-tooltip="$t('button-sets.form.email-input-tooltip')"
      :value="email_url.email"
      type="email"
      :label="$t(`button-sets.form.email-input-label`)"
      :placeholder="$t(`button-sets.form.email-input-placeholder`)"
      @input="$event ? (email_url.email = $event) : (email_url.email = '')"
    />
    <RdxInput
      v-model="email_url.subject"
      :label="$t(`button-sets.form.subject-input-label`)"
    />
    <RdxTextarea
      v-model="email_url.message"
      :label="$t(`button-sets.form.message-input-label`)"
    />
  </div>
</template>

<script>
import { RdxInput, RdxTextarea } from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "CiButtonSetEmailPartial",

  components: {
    RdxInput,
    RdxTextarea
  },

  props: {
    value: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      email_url: {
        email: "",
        subject: "",
        message: ""
      }
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (!newValue) return;
        let parts = newValue.split(/[?|&]/);
        for (let part of parts) {
          if (part.startsWith("mailto:"))
            this.email_url.email = part.replace("mailto:", "");
          if (part.startsWith("subject="))
            this.email_url.subject = decodeURIComponent(
              part.replace("subject=", "")
            );
          if (part.startsWith("body="))
            this.email_url.message = decodeURIComponent(
              part.replace("body=", "")
            );
        }
      }
    },

    email_url: {
      deep: true,
      handler(newValue) {
        let { email, subject, message } = newValue;

        let urlString = `mailto:${email}`;
        let subjectString = subject
          ? `subject=${encodeURIComponent(subject)}`
          : null;
        let messageString = message
          ? `body=${encodeURIComponent(message)}`
          : null;

        let firstParam = true;
        if (subjectString) {
          urlString += `?${subjectString}`;
          firstParam = false;
        }

        if (messageString) {
          urlString += `${firstParam ? "?" : "&"}${messageString}`;
          firstParam = false;
        }
        this.$emit("input", urlString);
      }
    }
  },

  mounted() {
    if (!this.email_url.subject) {
      this.email_url.subject = this.$t(
        "button-sets.form.subject-input-default-value"
      );
    }
    if (!this.email_url.message) {
      this.email_url.message = this.$t(
        "button-sets.form.message-input-default-value"
      );
    }
  }
};
</script>
