<template>
  <section class="new-list-view">
    <div class="new-list-view__header px-4 pt-4 pb-3">
      <CiBreadcrumbs :breadcrumbs="breadcrumbs" class="mb-2" />
      <div style="display: flex; align-items: center;">
        <RdxButton
          rect
          :to="{ name: 'CarsInventoryPublications' }"
          class="ci-action--back"
        >
          <RdxIcon class="ri-arrow-left-line rdx-icon--24" />
        </RdxButton>
        <CiHeading level="1" class="ml-2">
          {{ $t("publications.new-list-header") }}
        </CiHeading>
        <div class="new-list-view__actions">
          <RdxButton
            outline
            class="mr-2"
            :to="{ name: 'CarsInventoryPublications' }"
          >
            {{ $t("global.cancel") }}
          </RdxButton>
          <RdxButton
            :disabled="!isFilled || pending.load"
            :loading="pending.creating"
            filled
            @click="createListing"
          >
            {{ $t("global.save") }}
          </RdxButton>
        </div>
      </div>
    </div>

    <div class="new-list-view__inner">
      <div class="p-5">
        <div class="row new-list-view__grid">
          <div>
            <CiHeading level="2" class="mb-2">
              {{ $t("publications.shared-form.header") }}
            </CiHeading>
            <RdxInfo>
              {{ $t("publications.shared-form.description") }}
            </RdxInfo>
          </div>

          <div class="col-xl-9">
            <RdxInput
              v-model="listing.title"
              :disabled="pending.load || pending.creating"
              :label="$t('publications.shared-form.title-label')"
              :placeholder="$t('publications.shared-form.title-placeholder')"
            />

            <div class="row">
              <div class="col-6">
                <RdxSelect
                  v-model="listing.button_set"
                  class="mb-2"
                  :disabled="pending.load || pending.update"
                  :options="sets"
                  track-by="id"
                  :label="$t('publications.shared-form.button_set-input-label')"
                  :placeholder="
                    $t('publications.shared-form.button_set-input-placeholder')
                  "
                  data-cy="button_set"
                >
                  <template #singleLabel="{ option }">
                    {{ option.attributes.name }}
                  </template>
                  <template #option="{ option }">
                    {{ option.attributes.name }}
                  </template>
                </RdxSelect>
                <div class="mb-3">
                  <router-link
                    :to="{
                      name: 'CarsInventoryButtonSets'
                    }"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ $t("publications.shared-form.show-all-sets") }}
                  </router-link>
                  <a
                    class="new-list-view__link"
                    data-cy="new-list-view__link"
                    @click="openButtonSetModal"
                    >{{ $t("publications.shared-form.create-new-set") }}</a
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <RdxSelect
                  v-model="selectedSortOption"
                  :disabled="pending.load || pending.creating"
                  :options="sortOptions"
                  :placeholder="
                    $t('publications.shared-form.sorting-option-placeholder')
                  "
                  :label="$t('publications.shared-form.sorting-option-label')"
                >
                  <template #singleLabel="{ option: { attr, order } }">
                    {{
                      $t(
                        `publications.shared-form.sorting-opts.${attr}.${order}`
                      )
                    }}
                  </template>
                  <template #option="{ option: { attr, order } }">
                    {{
                      $t(
                        `publications.shared-form.sorting-opts.${attr}.${order}`
                      )
                    }}
                  </template>
                </RdxSelect>
              </div>
            </div>

            <RdxInfo icon="ri-information-line">
              {{ $t("publications.shared-form.note") }}
            </RdxInfo>
          </div>
        </div>
      </div>
    </div>
    <RdxModal
      v-if="buttonSetModalOpened"
      class="new-list-view__modal--fullscreen"
      @close-modal="closeButtonSetModal"
    >
      <template #header>
        <span>
          {{ $t("publications.shared-form.new-button-set") }}
        </span>
      </template>
      <NewButtonSet
        in-modal
        @close-modal="closeButtonSetModal"
        @save="onButtonSetSave($event)"
      />
    </RdxModal>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import {
  RdxIcon,
  RdxInfo,
  RdxButton,
  RdxInput,
  RdxSelect,
  RdxModal
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiBreadcrumbs from "@/components/atoms/CiBreadcrumbs.vue";
import CiHeading from "@/components/atoms/CiHeading";
import NewButtonSet from "@/views/cars-inventory/buttons/NewButtonSet";

export default {
  name: "NewListView",

  components: {
    RdxIcon,
    RdxInfo,
    RdxButton,
    RdxInput,
    RdxSelect,
    RdxModal,
    CiBreadcrumbs,
    CiHeading,
    NewButtonSet
  },

  data() {
    return {
      pending: {
        load: false,
        creating: false
      },
      breadcrumbs: [
        { text: "CarsInventory", link: { name: "CarsInventoryStock" } },
        {
          text: this.$t("navbar.publications"),
          link: { name: "CarsInventoryPublications" }
        },
        { text: this.$t("publications.new-list-header") }
      ],

      listing: {
        title: null,
        sort_attribute: "created_at",
        sort_order: "desc",
        button_set: null
      },

      relationships: {
        button_set: {
          data: {
            id: null
          }
        }
      },

      options: {
        sort_order: ["asc", "desc"],
        sort_attribute: [
          "production_year",
          "created_at",
          "mileage",
          "display_price"
        ]
      },

      buttonSetModalOpened: false
    };
  },

  computed: {
    sortOptions() {
      const options = [];
      const { sort_order, sort_attribute } = this.options;
      for (let attr of sort_attribute) {
        for (let order of sort_order) {
          options.push({ order, attr });
        }
      }
      return options;
    },

    selectedSortOption: {
      get() {
        return this.sortOptions.find(
          o =>
            o.attr === this.listing.sort_attribute &&
            o.order === this.listing.sort_order
        );
      },

      set({ attr, order }) {
        this.listing.sort_attribute = attr;
        this.listing.sort_order = order;
      }
    },

    isFilled() {
      return !!this.listing.title;
    },
    ...mapGetters({
      sets: "buttonSet/getAll"
    })
  },

  watch: {
    "listing.button_set"(button_set) {
      if (button_set) {
        this.relationships.button_set.data.id = button_set.id
          ? parseInt(button_set.id)
          : null;
      }
    }
  },

  async created() {
    this.pending.load = true;
    if (!this.sets.length) await this.$store.dispatch("buttonSet/fetchAll");
    this.pending.load = false;
  },

  methods: {
    createListing() {
      this.pending.creating = true;
      const { listing, relationships } = this;
      const LISTING = {
        data: {
          attributes: {
            sort_attribute: listing.sort_attribute,
            sort_order: listing.sort_order,
            title: listing.title
          },

          relationships
        }
      };
      this.$http
        .post("listings", LISTING)
        .then(
          ({
            data: {
              data: {
                attributes: { title },
                id
              }
            }
          }) => {
            this.addNewListToUserSavedListings(id);
            this.$router.push({ name: "CarsInventoryPublications" });
            this.$toast.info(
              this.$t(`publications.shared-form.success-create-with-title`, {
                title
              })
            );

            this.$gtm.trackEvent({
              action: "add",
              category: "publication_list",
              label: `${title}`,
              value: 1
            });
          }
        )
        .finally(() => (this.pending.creating = false));
    },

    addNewListToUserSavedListings(id) {
      let currentUuid = window.localStorage.getItem("company_uuid");
      let storedData = window.localStorage.getItem("user-listings-ids");
      let dataToStore = storedData ? JSON.parse(storedData) : {};
      let currentIds = dataToStore[currentUuid] || [];

      dataToStore[currentUuid] = [id, ...currentIds];
      dataToStore = JSON.stringify(dataToStore);
      localStorage.setItem("user-listings-ids", dataToStore);
    },

    openButtonSetModal() {
      this.buttonSetModalOpened = true;
    },
    closeButtonSetModal() {
      this.buttonSetModalOpened = false;
    },
    onButtonSetSave(payload) {
      this.closeButtonSetModal();
      this.pending.load = true;
      this.$store.dispatch("buttonSet/fetchAll").then(() => {
        this.listing.button_set = payload;
      });
      this.pending.load = false;
    }
  }
};
</script>

<style lang="scss">
.ci-action--back {
  color: $grey-10;
  &:active:not(:disabled),
  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    color: $blue-6;
    border-color: transparent;
    &:before {
      opacity: 0;
    }
  }
}
.new-list-view {
  &__inner {
    display: contents;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 186px);
    overflow: auto;
  }

  &__header {
    border-bottom: 1px solid $grey-5;
    padding: 17px 24px 21px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 25% 75%;
    > div {
      padding: 0rem 2rem;
    }
  }

  &__actions {
    position: absolute;
    right: 1.5rem;
  }

  &__link {
    cursor: pointer;
    &:before {
      content: "|";
      color: $grey-7;
      margin: 0 5px;
    }
  }

  &__modal {
    &--fullscreen {
      > .rdx-modal-box {
        width: 100%;
        height: 100%;
        animation: none;
        border-radius: 0;
        .rdx-modal-box__content {
          max-height: unset;
        }
      }
    }
  }
}
</style>
