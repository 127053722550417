<template>
  <div class="ci-button-section row">
    <div class="col-9">
      <slot name="header" />
      <slot name="description" />
    </div>
    <div class="col-3 align-self-center">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CiButtonSetWrapper"
};
</script>
